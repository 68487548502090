import './RideDetails.css';
import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { BOOK_RIDES_TABLE } from '../libs/constants.js';
import { printDate, getServiceLocation } from '../libs/utils.js';

import RideMap from'./RideMap';

function RideDetails(props) {
    const ride = props.ride;
    const isLargeViewPort = props?.isLargeViewPort || false;

    const from = ride.useReturnTrip ? ride.leg.returnFrom : ride.leg.from;
    const to = ride.useReturnTrip ? ride.leg.returnTo : ride.leg.to;
    const pickUpDateTime = ride.useReturnTrip ? ride.leg.returnPickUpDateTime : ride.leg.pickUpDateTime;

    return (<div className="RideDetails">
                <div className="RideInfo">
                    <div className="RideInfoContainer">
                        <div className="TitleHeader">Ryde ID {ride.confirmationCode}</div>
                        <div className="Header">Service Location</div>
                        <div className="Detail">Service location: {getServiceLocation(from)}</div>
                        <div className="Header">Ryde Details</div>
                        <div className="Detail">Pick-up location: {from}</div>
                        <div className="Detail">Drop location: {to}</div>
                        <div className="Detail">Pick-up date: {printDate(pickUpDateTime)}</div>
                        <div className="Detail">Luggage: {ride.leg.bags.luggage}</div>
                        <div className="Detail">Irregular Bags: {ride.leg.bags.irregularBags}</div>
                        <div className="Detail">Oversized Bags: {ride.leg.bags.overSizedBags}</div>
                        <div className="DetailI">Passengers: {ride.leg.passengers}</div>
                        <div className="Header">Service Level</div>
                        <div className="Detail">Level selected: {ride.level}</div>
                        <div className="Header">Primary passenger details</div>
                        <div className="Detail">First Name: {ride.passenger.firstName}</div>
                        <div className="Detail">Last Name: {ride.passenger.lastName}</div>
                        <div className="Detail">Phone: {ride.passenger.phone}</div>
                        <div className="Detail">Email: {ride.passenger.email}</div>
                    </div>
                </div>
                <div className="RideMap">
                    <RideMap from={ride.leg.fromPlaceId} to={ride.leg.toPlaceId} duration={ride.leg?.estimatedDuration?.value || 'N/A'}/>
                </div>
            </div>);
}

export default RideDetails;