export const IS_PROD = process.env.REACT_APP_IS_PROD === '1';  // process.env.REACT_APP_IS_PROD === '1';
export const SAFE = 'safe';
export const SECURE = 'secure';
export const ULTRA_SECURE = 'ultra-secure';
export const HOURLY_BASE = 70;

const ENV_PREFIX = IS_PROD ? 'prod/tux' : 'dev/tux';

export const BOOKED_RIDES_TABLE = `${ENV_PREFIX}/BookedRides`;
export const RYDERS_TABLE = `${ENV_PREFIX}/ryders`;
export const DRIVERS_TABLE = `${ENV_PREFIX}/drivers`;

export const VIEWPORT_LIMIT = 800;