import './RydeList.css';
import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import { printDate, getDate } from '../libs/utils.js';

import dayjs from 'dayjs';

function RydeList(props) {
    const driverBlocks = () => {
        if (!props.drives) {
            return [];
        }
        const drives = [];
        const now = dayjs();
        for (let i = 0; i < props.drives.length; i++) {
            const drive = props.drives[i];
            const pickUpDateTime = drive.useReturnTrip ? drive.leg.returnPickUpDateTime : drive.leg.pickUpDateTime;
            const from = drive.useReturnTrip ? drive.leg.returnFrom : drive.leg.from;
            const theDate = getDate(pickUpDateTime );
            drives.push(
                    <div className={theDate.isAfter(now) ? "RydeListDriveBlock": "RydeListDriveBlockPast"}>
                        <div className="RydeListTime">{printDate(pickUpDateTime)}</div>
                        <div className="PickUp">Pick Up: {from}</div>
                        <div><Button autoFocus={(i === 0)} onClick={()=> { props.setSelectedDriver(drive)}}>Details</Button></div>
                    </div>
               );

         }
         return drives;
    };


    return (
        <div className="RydeList">
            <Stack direction="column">
                {driverBlocks()}
            </Stack>
        </div>);
}

export default RydeList;