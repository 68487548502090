import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

import { query, collection, where, getDoc, getDocs, doc } from "firebase/firestore";
import { DRIVERS_TABLE } from './constants.js';

export async function  getDriverInfo(db, userId) {
    const driverDoc = await getDocs(query(collection(db, DRIVERS_TABLE), where('uid', '==', userId)));
    if (driverDoc.docs.length === 1) {
        const drive = driverDoc.docs[0].data();
        return {...driverDoc.docs[0].data(), id: driverDoc.docs[0].id };
    }
    return null;
}

export function printDate(dateString) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advanced);
    if (isNaN(new Date(dateString))) {
        return 'N/A';
    }
    let timeZone = 'America/Los_Angeles';
    if (dateString.includes('-05:00')) {
        timeZone = 'Jamaica';
    }
    return dayjs(dateString).tz(timeZone).format('MMMM D, YYYY [at] hh:mm A z');
}

export function getDate(dateString) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advanced);
    if (isNaN(new Date(dateString))) {
        return 'N/A';
    }
    let timeZone = 'America/Los_Angeles';
    if (dateString.includes('-05:00')) {
        timeZone = 'Jamaica';
    }
    return dayjs(dateString).tz(timeZone);
}

export function getServiceLocation(location) {
    if (location.includes('Sacramento')) {
        return 'Sacramento';
    } else if (location.includes('Jamaica')) {
       return 'Jamaica';
    }  else if (location.includes('Albuquerque')) {
       return 'Albuquerque';
    } else {
       return 'Unknown';
    }
}
